import Header from 'components/Header'
import React, { useRef } from 'react'
import Vis from './VisWithHooks'
import AboutSection from './AboutSection';
import WorkSection from './WorkSection';
import ContactSection from './ContactSection';

function App() {
  const profileimage = new URL(
    './../assets/benjamin-rech-profil.jpg',
    import.meta.url
  ).href

  // Create refs for each section
  const aboutRef = useRef<HTMLDivElement>(null)
  const workRef = useRef<HTMLDivElement>(null)
  const contactRef = useRef<HTMLDivElement>(null)

  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth"});
    }
  }

  return (
    <div className="relative">
      <div className="h-screen sm:pb-40 lg:pb-48">
        <div className="relative mx-auto px-4 sm:static sm:px-6 lg:px-8">
          <Header
            scrollToSection={scrollToSection}
            aboutRef={aboutRef}
            workRef={workRef}
            contactRef={contactRef}
          />
          <Vis />
          <div className="my-36 text-center">
            <h1
              id="head"
              className="introhead text-5xl font-bold tracking-tight text-gray-900 lg:text-9xl sm:text-6xl"
            >
              Hy, i am Ben!
            </h1>
            <h1 className="text-5xl font-bold font-outline-2 tracking-tight text-white lg:text-9xl sm:text-6xl">
              Hy, i am Ben!
            </h1>
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 lg:text-9xl sm:text-6xl">
              Hy, i am Ben!
            </h1>
            <div className="mt-8 ">
              <p className="font-mono tracking-tight text-gray-900">
                (Braunschweig, Germany)
              </p>
            </div>
          </div>
          </div>

        <AboutSection ref={aboutRef} />
<WorkSection ref={workRef} />
<ContactSection ref={contactRef} />


        <div className="bg-black py-8 px-4 sm:static sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="col-span-1 sm:col-span-2 lg:col-span-1">
              <p className="text-2xl font-bold text-white">( Impressum )</p>
              <p className="font-mono text-white">Benjamin Rech</p>
              <p className="font-mono text-white">
              <a
                  target="_blank"
                  href="mailto:mail@benjamin-rech.de"
                >
                Email: mail@benjamin-rech.de
                </a>
              </p>
              <p className="font-mono text-white">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/benjaminrech/"
                >
                  LinkedIn
                </a>
              </p>
            </div>
            <div className="col-span-1 sm:col-span-2 lg:col-span-3">
              <p className="text-2xl font-bold text-white">( Datenschutz )</p>
              <ul className="font-mono text-xs list-decimal list-inside text-white">
                <li>
                  Wenn du Fragen zum Datenschutz hast, schreib mir bitte eine
                  E-Mail. Unter den angegebenen Kontaktdaten im Impressum kannst
                  du jederzeit folgende Rechte ausüben:
                </li>
                <ul className="font-mono text-xs indent-4 list-disc list-inside text-white">
                  <li>
                    Du kannst Auskunft über deine bei mir gespeicherten Daten
                    und deren Verarbeitung (Art. 15 DSGVO) verlangen.
                  </li>
                  <li>
                    Falls deine persönlichen Daten unrichtig sind, hast du das
                    Recht, sie von mir berichtigen zu lassen (Art. 16 DSGVO).
                  </li>
                  <li>
                    Du kannst die Löschung deiner bei mir gespeicherten Daten
                    beantragen (Art. 17 DSGVO).
                  </li>
                  <li>
                    Falls gesetzliche Pflichten die Löschung deiner Daten
                    verhindern, kannst du die Einschränkung der
                    Datenverarbeitung verlangen (Art. 18 DSGVO).
                  </li>
                  <li>
                    Du kannst der Verarbeitung deiner Daten durch mich
                    widersprechen (Art. 21 DSGVO).
                  </li>
                  <li>
                    Wenn du in die Datenverarbeitung eingewilligt hast oder
                    einen Vertrag mit mir abgeschlossen hast, hast du das Recht
                    auf Datenübertragbarkeit (Art. 20 DSGVO).
                  </li>
                  <li>
                    Falls du mir eine Einwilligung zur Datenverarbeitung erteilt
                    hast, kannst du diese jederzeit mit Wirkung für die Zukunft
                    widerrufen. Du kannst dich auch jederzeit mit einer
                    Beschwerde an eine Aufsichtsbehörde wenden, zum Beispiel an
                    die Aufsichtsbehörde des Bundeslands, in dem du deinen
                    Wohnsitz hast, oder an die für mich als verantwortliche
                    Stelle zuständige Behörde. Eine Liste der Aufsichtsbehörden
                    (für den nichtöffentlichen Bereich) mit Anschrift findest du
                    unter:{' '}
                    <a
                      href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
                      className="text-blue-500 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
                    </a>
                    .
                  </li>
                </ul>
                <li>
                  Nutzung von Matomo: Falls du deine Einwilligung gegeben hast,
                  setzen wir auf dieser Website Matomo ein, eine
                  Open-Source-Software zur statistischen Auswertung von
                  Besucherzugriffen. Der Anbieter von Matomo ist die InnoCraft
                  Ltd., 150 Willis St, 6011 Wellington, Neuseeland.
                </li>
                <li>
                  Matomo setzt einen Cookie (eine Textdatei) auf deinem
                  Endgerät, mit dem dein Browser wiedererkannt werden kann. Wenn
                  du Unterseiten unserer Website aufrufst, werden folgende
                  Informationen gespeichert:
                  <ul className="font-mono indent-4 text-xs list-disc list-inside text-white">
                    <li>
                      deine IP-Adresse, wobei die letzten zwei Bytes
                      anonymisiert werden,
                    </li>
                    <li>
                      die aufgerufene Unterseite und der Zeitpunkt des Aufrufs,
                    </li>
                    <li>
                      die Seite, von der du auf unsere Webseite gelangt bist
                      (Referrer),
                    </li>
                    <li>
                      welcher Browser, welches Betriebssystem und welche
                      Bildschirmauflösung du nutzt,
                    </li>
                    <li>die Verweildauer auf der Website,</li>
                    <li>
                      die Seiten, die von der aufgerufenen Unterseite aus
                      angesteuert werden.
                    </li>
                  </ul>
                </li>
                <li>
                  Durch die Anonymisierung der IP-Adresse um sechs Stellen
                  tragen wir zur Wahrung des Schutzes personenbezogener Daten
                  bei. Die Daten werden nicht dazu genutzt, dich persönlich zu
                  identifizieren, und werden nicht mit anderen Daten
                  zusammengeführt. Die durch das Cookie erzeugten Informationen
                  über deine Benutzung dieser Webseite werden nicht an Dritte
                  weitergegeben.
                </li>
                <li>
                  Widerruf der Einwilligung: Du kannst deine Einwilligung zur
                  Speicherung und Auswertung deiner Daten durch Matomo jederzeit
                  über den unten aufgeführten Link widerrufen. Es wird dann ein
                  Opt-Out-Cookie auf deinem Gerät gespeichert, das zwei Jahre
                  gültig ist. Das hat zur Folge, dass Matomo keine Sitzungsdaten
                  mehr erhebt. Beachte jedoch, dass das Opt-Out-Cookie gelöscht
                  wird, wenn du alle Cookies löschst.
                </li>
                <li>
                  [Hier füge ich das IFRAME für den Opt-Out von Matomo ein]
                </li>
                <li>
                  Weitere Informationen zu den Privatsphäre-Einstellungen der
                  Matomo Software findest du unter folgendem Link:{' '}
                  <a
                    href="https://matomo.org/docs/privacy/"
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://matomo.org/docs/privacy/
                  </a>
                  .
                </li>
                <li>
                  Du kannst die Verwendung von Cookies durch eine entsprechende
                  Einstellung deines Browsers verhindern.
                </li>
                <li>
                  SSL-Verschlüsselung: Um die Sicherheit deiner Daten bei der
                  Übertragung zu schützen, verwenden wir
                  Verschlüsselungsverfahren, die dem aktuellen Stand der Technik
                  entsprechen, zum Beispiel SSL über HTTPS.
                </li>
                <li>
                  Information über dein Widerspruchsrecht nach Art. 21 DSGVO: Du
                  hast das Recht, aus Gründen, die sich aus deiner besonderen
                  Situation ergeben, jederzeit gegen die Verarbeitung deiner
                  personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. f
                  DSGVO (Datenverarbeitung auf der Grundlage einer
                  Interessenabwägung) erfolgt, Widerspruch einzulegen.
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <style>{`
        .font-outline-2 {
          -webkit-text-stroke: 1px black;
        }
        .font-outline-4 {
          -webkit-text-stroke: 2px black;
        }
        .vis {
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      html,body{
          overflow-x: hidden;
      }
      `}</style>
    </div>
  )
}

export default App
