import React, { useState, useRef } from 'react';

interface HeaderProps {
  scrollToSection: (ref: React.RefObject<HTMLElement>) => void;
  aboutRef: React.RefObject<HTMLElement>;
  workRef: React.RefObject<HTMLElement>;
  contactRef: React.RefObject<HTMLElement>;
}

export default function Header(props: HeaderProps) {
  const { scrollToSection, aboutRef, workRef, contactRef } = props;
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeMenu = () => {
    setIsNavOpen(false);
  };

  return (
    <div className="flex items-center justify-between py-6">
      <a href="/">
        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          benjamin rech
        </h1>
      </a>
      <nav>
        <section className="flex lg:hidden">
          <div className="space-y-2" onClick={toggleNav}>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
          </div>

          {isNavOpen && (
            <div className="mobileNav">
              <div className="absolute top-0 right-0 px-8 py-8" onClick={closeMenu}>
                <svg
                  className="h-8 w-8 text-gray-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
              <ul className="flex flex-col items-center justify-between min-h-[250px]" onClick={closeMenu}>
                <li className="border-b border-gray-400 my-8 text-2xl uppercase">
                <button onClick={() => { console.log("About clicked"); scrollToSection(aboutRef); }}>About</button>
                </li>
                <li className="border-b border-gray-400 my-8 text-2xl uppercase">
                <button onClick={() => { console.log("Work clicked"); scrollToSection(workRef); }}>Work</button>
                </li>
                <li className="border-b border-gray-400 my-8 text-2xl uppercase">
                <button onClick={() => { console.log("Contact clicked"); scrollToSection(contactRef); }}>Contact</button>
                </li>
              </ul>
            </div>
          )}
        </section>

        <ul className="hidden space-x-8 lg:flex font-mono">
          <li>
            <button onClick={() => scrollToSection(aboutRef)}>About</button>
          </li>
          <li>
            <button onClick={() => scrollToSection(workRef)}>Work</button>
          </li>
          <li>
            <button onClick={() => scrollToSection(contactRef)}>Contact</button>
          </li>
        </ul>
      </nav>
      <style>{`
        .mobileNav {
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background: white;
          z-index: 1000;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }
      `}</style>
    </div>
  );
}
