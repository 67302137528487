import  { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'

const VisWithHooks = () => {
  const mount = useRef(null)
  const [isAnimating, setAnimating] = useState(true)
  const controls = useRef(null)

  useEffect(() => {
    let width = mount.current.clientWidth
    let height = mount.current.clientHeight
    let frameId

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })

    // Create edges geometry
    const geometry = new THREE.BoxGeometry(3, 3, 3, 32, 32, 32) // Increase segments here
    const edges = new THREE.EdgesGeometry(geometry)
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 })

    const cube = new THREE.LineSegments(edges, lineMaterial)

    camera.position.z = 4
    scene.add(cube)
    renderer.setSize(width, height)

    // Enable antialiasing
    renderer.setPixelRatio(window.devicePixelRatio)

    const renderScene = () => {
      renderer.render(scene, camera)
    }

    const handleResize = () => {
      const width = window.innerWidth
      const height = window.innerHeight
      // Update canvas size and camera aspect ratio
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderScene()
    }

    const animate = () => {
      cube.rotation.x += 0.01
      cube.rotation.y += 0.01

      renderScene()
      frameId = window.requestAnimationFrame(animate)
    }

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate)
      }
    }

    const stop = () => {
      cancelAnimationFrame(frameId)
      frameId = null
    }

    mount.current.appendChild(renderer.domElement)
    window.addEventListener('resize', handleResize)
    start()

    controls.current = { start, stop }

    return () => {
      stop()
      window.removeEventListener('resize', handleResize)
      mount.current.removeChild(renderer.domElement)

      scene.remove(cube)
      geometry.dispose()
      lineMaterial.dispose()
    }
  }, [])

  useEffect(() => {
    if (isAnimating) {
      controls.current.start()
    } else {
      controls.current.stop()
    }
  }, [isAnimating])

  return (
    <div
      className="vis mt-20"
      ref={mount}
      onClick={() => {
        setAnimating(!isAnimating);
      }}
    />
  )
}

export default VisWithHooks
