import React, { useRef } from 'react'

const ContactSection = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
    return (
       <div ref={ref} className="bg-black py-16 px-4 sm:static sm:px-6 lg:px-8 text-center">
      <p className="text-white text-4xl lg:text-7xl sm:text-6xl">
        Let's talk
      </p>
      <button className="mt-4 py-2 px-4 border border-white text-white rounded-lg hover:bg-white hover:text-black transition duration-300 ease-in-out">
        <a target="_blank" href="https://cal.com/benjaminrech/30min">
          Termin buchen
        </a>
      </button>
    </div>
    )
  });
  
export default ContactSection;
