import React, { useRef } from 'react'

const AboutSection = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const profileimage = new URL(
    './../assets/benjamin-rech-profil.jpg',
    import.meta.url
  ).href

  return (
    <div ref={ref}>
      <div className="my-4 sm:grid grid-cols-3 gap-8 px-4 sm:static sm:px-6 lg:px-8">
        <div className="col-span-2">
          <p className="text-xl font-mono font-bold tracking-tight text-gray-900">
            (About Me)
          </p>
          <p className="font-mono sm:text-xl">
            I'm Benjamin Rech, a consultant, designer, and web developer with
            expertise in UX/UI, marketing, and software development. Leveraging
            my entrepreneurial background and a strong academic foundation, I
            have successfully contributed to diverse projects, from founding and
            growing Startups to managing web applications and launching
            ecommerce like the "Urkunden Designer". Let's collaborate to make
            something that matters.
          </p>
        </div>
        <div className="col-span-1">
          <img
            className="h-auto max-w-full"
            src={profileimage}
            alt="Profilbild"
          />
        </div>
      </div>

      <div className="bg-black py-8 h-[600px] mt-[-240px] flex items-end px-4 sm:static sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8">
          <p className="text-white text-4xl lg:text-7xl sm:text-6xl">
            As a digital designer and developer, I focus on producing impactful
            digital experiences.
          </p>
          <div></div> {/* Empty grid item to occupy the other 50% */}
        </div>
      </div>
    </div>
  )
})

export default AboutSection;
