import React, { useRef } from 'react'

const skillsData = [
    {
      title: 'Digital Design',
      description: 'Brand Identity and Brand Design, Styleguides, Figma'
    },
    {
      title: 'Digital Marketing',
      description:
        'Marketing Strategy, Display Ad Campaigns, SEO, Content Creation'
    },
    {
      title: 'Business Development',
      description: 'Startups, Digital Sales Funnel, Market Research, E-Commerce'
    },
    {
      title: 'Web Development',
      description: 'Frontend, React, Wordpress, HTML/CSS/JS'
    }
  ]

  const WorkSection = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
    const workRef = useRef<HTMLDivElement>(null)
    return (
       <div ref={ref} className="mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-16">
        <div className="mb-6 sm:mb-0 row-span-2">
          <p className="text-2xl font-bold tracking-tight text-gray-900">
            (Skills)
          </p>
        </div>
        {skillsData.map((skill, index) => (
          <div className="mb-6 sm:mb-0" key={index}>
            <p className="text-2xl font-mono font-bold tracking-tight text-gray-900">
              {skill.title}
            </p>
            <p className="font-mono tracking-tight text-gray-900 py-4">
              {skill.description}
            </p>
          </div>
        ))}
      </div>
    </div>
    );
  });
  
  export default WorkSection;